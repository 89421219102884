<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('dtouch.issues.title', locale) }}</v-expansion-panel-header>
    <v-expansion-panel-content style="padding-top:16px;">
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-text-field
            v-model="mails"
            :label="$t('dtouch.request.email', locale)"
            outlined
            dense
            hide-details
            @change="handleChange"
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
export default {
  name: 'RequestsForm',
  props: {
    workspaceID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    mails: null,
    workspaces: [],
    loading: true,
  }),
  methods: {
    getData () {
      api.getItem('dtouch', `v1/system/workspaces/`, `${this.workspaceID}/issues-config`)
        .then(response => {
          this.mails = response ? response.NotificationMails : null
        })
    },
    handleExpand () {
      if(!this.mails && this.workspaceID) this.getData()
    },
    handleChange () {
      this.onChangeData({
        NotificationMails: this.mails,
      })
    }
  },
}
</script>

